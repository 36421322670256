<template>
  <a-spin v-if="loading" class="text-center d-block p-3" />
  <template v-else>
    <div class="mb-2">
      <a-select
        v-model:value="localEvent.pluginData.pluginId"
        allowClear
        show-search
        placeholder="Select Plugin"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="plugin in plugins" :key="plugin._id">
          <a-select-option :value="plugin._id" :label="plugin.title">
            {{ plugin.title }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="selectedPlugin">
      <a-select
        v-model:value="localEvent.pluginData.endPointId"
        allowClear
        show-search
        placeholder="Select EndPoint"
        style="width: 50%"
        :filter-option="filterOption"
        @change="updateInputOutputFields"
      >
        <template v-for="endPoint in selectedPlugin.endPoints" :key="endPoint._id">
          <a-select-option :value="endPoint._id" :label="endPoint.name">
            {{ endPoint?.name !== '' ? endPoint?.name : endPoint?.path }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="localEvent.pluginData.endPointId">
      <a-select
        v-model:value="localEvent.pluginData.method"
        allowClear
        show-search
        placeholder="Select Method"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="method in methods" :key="method.id">
          <a-select-option :value="method.value" :label="method.label">
            {{ method.label }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="localEvent.pluginData.endPointId">
      <a-select
        v-model:value="localEvent.pluginData.environment"
        allowClear
        show-search
        placeholder="Select Environment"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="environment in environments" :key="environment.id">
          <a-select-option :value="environment.value" :label="environment.label">
            {{ environment.label }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <a-tabs type="card" style="margin-bottom: 20px; width: 50%">
      <a-tab-pane key="1" tab="Header params">
        <div class="row mb-3" v-for="(body, index) in localEvent.pluginData.headerParams" :key="'body_' + index">
          <div class="col-4">
            <a-input v-model:value="body.key" placeholder="Key" />
          </div>
          <div class="col-7">
            <a-input v-model:value="body.value" placeholder="Value" />
          </div>
          <div class="col-1">
            <a-button @click="removeHeaderParam(body)"><DeleteOutlined /></a-button>
          </div>
        </div>

        <a-button @click="addHeaderParam()" style="margin: 0px 5px 5px 0px"><PlusOutlined /> Add param</a-button>
        <a-button @click="showAuthTokenModal = true" style="margin: 0px 5px 5px 0px"><PlusOutlined /> Add authentication token</a-button>
        <a-button size="small" @click="openTokens = true" type="primary" style="float: right">Tokens to use</a-button>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Blueprint params">
        <template v-for="field in mainBlueprintFields" :key="field._id">
          <div class="blueprint-fields">
            <div class="cell input-name">
              <div style="position: absolute; top: 50%; transform: translateY(-50%)">
                <a-tooltip>
                  <template #title>
                    <span>Select this field</span>
                  </template>
                  <a-checkbox :checked="fieldValue(field, 'active')" @change="value => changeFieldValue(value, field, 'active')" />
                </a-tooltip>
              </div>
              <div style="display: inline-block; padding-left: 27px">
                <strong>{{ field.label }}</strong>
                <div class="mutted">{{ field.structure.type }}</div>
              </div>
            </div>
            <div class="cell modify-filed">
              <div class="row">
                <div class="col-10">
                  <ReferenceFields
                    v-if="field.structure.type === 'reference' && localEvent.pluginData.blueprintParams[indexOfField(field)]"
                    v-model="toReferenceFieldIds[indexOfField(field)]"
                    :key="referenceFieldsKey"
                    :blueprints="allBlueprints"
                    :selectedBlueprint="mainBlueprint"
                    :toFieldFromOtherBlueprint="false"
                    @toField="(value, index) => selectField(field, value, index, indexOfField(field))"
                  />
                </div>
                <div class="col-2" style="display: flex; justify-content: center; align-items: center">
                  <a-tooltip>
                    <template #title>
                      <span>Clear reference fields before any modifications</span>
                    </template>
                    <a-button
                      v-if="field.structure.type === 'reference' && localEvent.pluginData.blueprintParams[indexOfField(field)]"
                      @click="clearReference(indexOfField(field))"
                      ><DeleteOutlined />
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
              <a-input
                :default-value="fieldValue(field, 'key')"
                @change="value => changeFieldValue(value, field, 'key')"
                placeholder="Plugin param key"
              />
            </div>
          </div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Custom params">
        <div class="row mb-3" v-for="(body, index) in localEvent.pluginData.customParams" :key="'body_' + index">
          <div class="col-4">
            <a-input v-model:value="body.key" placeholder="Key" />
          </div>
          <div class="col-7">
            <a-input v-model:value="body.value" placeholder="Value" />
          </div>
          <div class="col-1">
            <a-button @click="removeCustomParam(body)"><DeleteOutlined /></a-button>
          </div>
        </div>

        <a-button @click="addCustomParam()"><PlusOutlined /> Add param</a-button>
        <a-button size="small" @click="openTokens = true" type="primary" style="float: right">Tokens to use</a-button>
      </a-tab-pane>
    </a-tabs>
    <div v-if="selectedEndPoint?.expectedFields">
      Expected fields to return:
      <Codemirror
        disabled
        :model-value="codemirrorDataToStringify"
        :options="{
          btabSize: 4,
          mode: 'text/html',
          theme: 'base16-dark',
          lineNumbers: true,
          line: true,
        }"
        :extensions="extensions"
      />
    </div>

    <a-modal v-model:open="showAuthTokenModal" title="Add authentication token" width="720px" :footer="false">
      <AuthTokens @tokenSelected="addAuthToken" />
    </a-modal>
    <TokensModal :visible="openTokens" @close="openTokens = false" :blueprintId="selectedBlueprintId" />
  </template>
</template>

<script>
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { blueprintApi } from '@/apps/dataSystem/api';
import { pluginsManagementApi } from '@/apps/pluginsManagement/api';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import TokensModal from '@core/components/TokensModal';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFields.vue';
import AuthTokens from '@/apps/dataProvider/views/dataProviderBuilder/components/forms/subscription/components/authTokens.vue';

export default {
  name: 'workflowBuilderPluginData',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'eventIndex', 'prevEvent'],
  components: {
    Codemirror,
    DeleteOutlined,
    PlusOutlined,
    ReferenceFields,
    AuthTokens,
    TokensModal,
  },
  data() {
    return {
      loading: false,
      extensions: null,
      roles: [],
      subtenants: [],
      localEvent: { ...this.event },
      allBlueprints: [],
      mainBlueprint: null,
      mainBlueprintFields: [],
      plugins: [],
      methods: [
        { id: 1, label: 'GET', value: 'GET' },
        { id: 2, label: 'POST', value: 'POST' },
      ],
      environments: [
        { id: 1, label: 'DEVELOPMENT', value: 'DEVELOPMENT' },
        { id: 2, label: 'PRODUCTION', value: 'PRODUCTION' },
      ],
      headerParams: [],
      customParams: [],
      toFieldFromOtherBlueprint: false,
      toField: [],
      toReferenceFieldIds: [],
      referenceFieldsKey: 0,
      showAuthTokenModal: false,
      openTokens: false,
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    selectedPlugin() {
      if (this.plugins) {
        return this.plugins.find(plugin => plugin._id === this.localEvent.pluginData.pluginId);
      }
      return null;
    },
    selectedEndPoint() {
      if (this.selectedPlugin?.endPoints) {
        return this.selectedPlugin.endPoints.find(plugin => plugin._id === this.localEvent.pluginData.endPointId);
      }
      return null;
    },
    codemirrorDataToStringify() {
      return JSON.stringify(this.selectedEndPoint?.expectedFields ?? {}, null, 2);
    },
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },
  async created() {
    this.loading = true;
    this.extensions = [javascript(), oneDark];
    await this.fetchPlugins();
    await this.fetchBlueprint();
    this.loading = false;
    console.log(this.localEvent.pluginData);
  },
  mounted() {
    for (let i = 0; i < this.localEvent.pluginData.blueprintParams.length; i += 1) {
      this.toReferenceFieldIds[i] = this.localEvent.pluginData.blueprintParams[i].reference ?? [];
    }
  },
  methods: {
    updateInputOutputFields() {
      console.log(this.selectedEndPoint);
      this.localEvent.pluginData.inputFields = this.selectedEndPoint?.inputFields;
      this.localEvent.pluginData.expectedFields = this.selectedEndPoint?.expectedFields;
    },
    async fetchPlugins() {
      this.plugins = await pluginsManagementApi.getPluginsByApp();
    },
    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      await this.fetchAllBlueprint();

      this.mainBlueprint = blueprint;
      this.mainBlueprintFields = Object.values(fieldsById);
    },
    async fetchAllBlueprint() {
      this.allBlueprints = await blueprintApi.getAllWithFields();
    },
    filterOption(input, option) {
      return option.children()[0].children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
    indexOfField(field) {
      return this.localEvent.pluginData.blueprintParams
        .map(function (x) {
          return x.toFieldId;
        })
        .indexOf(field._id);
    },
    fieldValue(field, fieldName) {
      const findField = this.localEvent.pluginData.blueprintParams.find(f => f.toFieldId === field._id.toString());
      return findField?.[fieldName] ?? null;
    },
    changeFieldValue(event, field, fieldName) {
      const value = event?.target?.value ?? event?.target?.checked ?? event;
      const findField = this.localEvent.pluginData.blueprintParams.find(f => f.toFieldId === field._id);
      if (findField) {
        findField[fieldName] = value;
        if (fieldName === 'active') {
          findField.value = field.value;
        }
      } else {
        this.localEvent.pluginData.blueprintParams.push({
          active: fieldName === 'active' ? value : true,
          _id: field._id,
          toFieldId: field._id,
          key: '',
          value: field.value,
          structure: field.structure,
          reference: [],
          ...field,
        });
      }
    },
    selectField(field, value, index, blueprintIndex) {
      if (value?.fieldId) {
        const existingIndex = this.localEvent.pluginData.blueprintParams[blueprintIndex].reference.findIndex(item => item.fieldId === value.fieldId);
        if (existingIndex === -1) {
          this.localEvent.pluginData.blueprintParams[blueprintIndex].reference.push({ ...value });
        }
      }
    },
    removeCustomParam(row) {
      const key = this.localEvent.pluginData.customParams.indexOf(row);
      this.localEvent.pluginData.customParams = this.localEvent.pluginData.customParams.filter(
        r => this.localEvent.pluginData.customParams.indexOf(r) !== key
      );
    },
    addCustomParam() {
      this.localEvent.pluginData.customParams.push({
        key: undefined,
        value: undefined,
      });
    },
    removeHeaderParam(row) {
      const key = this.localEvent.pluginData.headerParams.indexOf(row);
      this.localEvent.pluginData.headerParams = this.localEvent.pluginData.headerParams.filter(
        r => this.localEvent.pluginData.headerParams.indexOf(r) !== key
      );
    },
    addHeaderParam() {
      this.localEvent.pluginData.headerParams.push({
        key: undefined,
        value: undefined,
      });
    },
    clearReference(blueprintIndex) {
      this.localEvent.pluginData.blueprintParams[blueprintIndex].reference = [];
      this.toReferenceFieldIds[blueprintIndex] = [];
      this.referenceFieldsKey += 1;
    },
    addAuthToken(payload) {
      this.localEvent.pluginData.headerParams.push({
        key: payload.key,
        value: '{{=token}}',
        authRestKey: payload.key,
        authRestResponseType: payload.responseType,
        authRestId: payload.authRestId,
      });

      this.showAuthTokenModal = false;
    },
  },
};
</script>
<style scoped>
.blueprint-fields {
  display: table;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}
.blueprint-fields:last-child {
  margin-bottom: 0px;
}
.blueprint-fields .cell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.mutted {
  font-size: 12px;
}
.input-name {
  width: 30%;
  border-right: 1px solid #ddd;
  height: 30px;
}
.modify-filed {
  width: 70%;
  padding-left: 10px;
}
</style>
