import { reactive } from 'vue';
import { menuBuilderApi } from '@menuBuilder/api';
import { SlugGetters } from '@/slug.store';

const initialState = {
  menusLoading: false,
  menuLoading: null,
  menus: [],
  currentAppURL: null,
};

const state = reactive({ ...initialState });

const Getters = {
  getMenusLoading: () => {
    return state.menusLoading;
  },
  getMenuLoading() {
    return state.menuLoading;
  },
  getMenus: () => {
    return state.menus;
  },
};

const Mutations = {};

const Actions = {
  init: async () => {
    await Actions.fetchMenus();
  },

  async fetchMenus() {
    if (state.menus.length && state.currentAppURL === SlugGetters.getAppUrl()) {
      return;
    }

    state.menusLoading = true;
    state.menus = await menuBuilderApi.getAll();
    state.currentAppURL = SlugGetters.getAppUrl();
    state.menusLoading = false;
  },

  async startPage(menuId, data) {
    state.menuLoading = menuId;
    const response = await menuBuilderApi.startPage(menuId, data);
    state.menus = state.menus.map(item => {
      if (item._id === menuId) {
        return response.data;
      }
      return item;
    });
    state.menuLoading = null;
  },

  async editOne(menuId, data) {
    state.menuLoading = menuId;
    const response = await menuBuilderApi.putOne(menuId, data);
    state.menus = state.menus.map(item => {
      if (item._id === menuId) {
        return response.data;
      }
      return item;
    });
    state.menuLoading = null;
  },
  async createOne(data) {
    const appLayout = await menuBuilderApi.getLayout(true);

    const response = await menuBuilderApi.postOne(data);
    state.menus.push(response.data);

    appLayout.leftColumn = [...appLayout.leftColumn, response.data._id];

    await menuBuilderApi.updateOneLayout(appLayout);
  },

  async deleteOne(menuId) {
    await menuBuilderApi.deleteOne(menuId);
    state.menus = state.menus.filter(item => item._id !== menuId);
  },
};

export const MenuGetters = Getters;
export const MenuMutations = Mutations;
export const MenuActions = Actions;
