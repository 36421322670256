<template>
  <a-modal v-model:open="openedModal" wrap-class-name="full-modal" title="Setup File Mapper">
    <template #footer>
      <a-button key="cancel" @click="cancelModal()">Cancel</a-button>
      <a-button key="delete" type="primary" danger @click="deleteField()">Delete</a-button>
      <a-button key="submit" type="primary" @click="saveField()">Save</a-button>
    </template>
    <div>
      <label>Name</label>
      <a-input v-model:value="fieldName" placeholder="Name" />
    </div>
    <div class="d-flex flex-column mt-3">
      <label>Type</label>
      <a-select v-model:value="fieldType" :required="true" placeholder="Select desired type of field...">
        <a-select-option v-for="type in types" :value="type" :key="type">
          {{ type }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="fieldType === 'date'">
      <label>Date Format</label>
      <a-input v-model:value="fieldDateFormat" placeholder="dd/mm/yyyy" />
    </div>
  </a-modal>
</template>

<script>
import { FileMapperActions } from '@fieldMapper/shared/fileMapper.store';
import { notification } from 'ant-design-vue';

export default {
  props: ['opened', 'selectedFileMapper', 'selectedFieldToEdit'],
  data() {
    return {
      fieldName: '',
      fieldType: 'string',
      fieldDateFormat: '',
      types: ['number', 'string', 'boolean', 'date'],
    };
  },
  mounted() {
    if (this.selectedFieldToEdit) {
      this.fieldName = this.selectedFieldToEdit.name;
      this.fieldType = this.selectedFieldToEdit.type;
      this.fieldDateFormat = this.selectedFieldToEdit.dateFormat ?? null;
    }
  },
  computed: {
    openedModal() {
      return this.opened;
    },
  },
  methods: {
    checkDateFormat(dateFormat) {
      // Ensure the format includes "dd", "mm", and "yyyy" in any order
      const requiredTokens = ['dd', 'mm', 'yyyy'];
      const missingTokens = requiredTokens.filter(token => !dateFormat.includes(token));

      if (missingTokens.length > 0) {
        notification.error({
          message: 'Invalid Date Format',
          description: `The date format must include "dd", "mm", and "yyyy". Missing: ${missingTokens.join(', ')}`,
        });
        return false;
      }
      return true;
    },
    deleteField() {
      FileMapperActions.deleteOneField(this.selectedFileMapper._id, this.selectedFieldToEdit._id);

      notification.success({
        message: 'Deleted',
        description: 'Field deleted with success.',
      });

      this.cancelModal();
    },
    saveField() {
      if (
        this.fieldName === '' ||
        this.fieldType === '' ||
        (this.fieldType === 'date' && this.fieldDateFormat === '' && !this.checkDateFormat(this.fieldDateFormat))
      ) {
        notification.warning({
          message: 'Warning',
          description: `Please fill up all the fields.`,
        });
        return;
      }

      if (this.selectedFieldToEdit) {
        FileMapperActions.editOneField(this.selectedFileMapper._id, this.selectedFieldToEdit._id, {
          name: this.fieldName,
          type: this.fieldType,
          dateFormat: this.fieldDateFormat,
        });
      } else {
        FileMapperActions.createOneField(this.selectedFileMapper._id, {
          name: this.fieldName,
          type: this.fieldType,
          dateFormat: this.fieldDateFormat,
        });
      }

      this.fieldName = null;
      this.fieldType = null;
      this.fieldDateFormat = null;

      notification.success({
        message: 'Saved',
        description: 'Field created with success.',
      });

      this.cancelModal();
    },
    cancelModal() {
      this.$emit('cancelModal');
    },
  },
};
</script>

<style></style>
