import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

document.addEventListener('updateMessage', async e => {
  if (localStorage.getItem('nonAllocatedMessageId')) {
    await axios.put(`${getSubtenantUrl()}/sms/link`, {
      instanceId: e.detail.instanceId,
      id: localStorage.getItem('nonAllocatedMessageId'),
    });
    localStorage.removeItem('nonAllocatedMessageId');
  }

  if (localStorage.getItem('nonAllocatedWhatsAppId')) {
    await axios.put(`${getSubtenantUrl()}/whatsapp/link`, {
      instanceId: e.detail.instanceId,
      id: localStorage.getItem('nonAllocatedWhatsAppId'),
    });
    localStorage.removeItem('nonAllocatedWhatsAppId');
  }
});
