<template>
  <a-modal v-model:open="openedModal" wrap-class-name="full-modal" title="Setup File Mapper" @ok="save()" @cancel="cancelModal()">
    <div>
      <label>Name</label>
      <a-input v-model:value="mapperName" placeholder="Name" />
    </div>
    <div>
      <label>Description</label>
      <a-input v-model:value="mapperDescription" placeholder="Description" />
    </div>
  </a-modal>
</template>

<script>
import { FileMapperActions } from '@fieldMapper/shared/fileMapper.store';
import { notification } from 'ant-design-vue';

export default {
  props: ['opened', 'fieldMapperId'],
  data() {
    return {
      mapperName: '',
      mapperDescription: '',
    };
  },
  computed: {
    openedModal() {
      return this.opened;
    },
  },
  methods: {
    save() {
      FileMapperActions.createOneFileMapper({
        name: this.mapperName,
        description: this.mapperDescription,
        fieldMapperId: this.fieldMapperId,
      });

      if (this.mapperName === '' || this.mapperDescription === '') {
        notification.warning({
          message: 'Warning',
          description: `Please fill up all the fields.`,
        });
      }

      this.mapperName = null;
      this.mapperDescription = null;

      notification.success({
        message: 'Saved',
        description: 'File Mapper created with success.',
      });

      this.cancelModal();
      this.$emit('updateFileMapperList');
    },
    cancelModal() {
      this.$emit('cancelModal');
    },
    successMessage() {
      notification.success({
        message: 'Saved',
        description: 'File Mapper created with success.',
      });
    },
  },
};
</script>

<style></style>
