<template>
  <div class="text-center pt-2 pb-2" v-if="loading || !pluginInputData">
    <a-spin />
  </div>
  <div v-else class="padd-20">
    <a-card>
      <h4>{{ localPlugin?.title }}</h4>
      <a-tag color="cyan">Version: {{ localPlugin?.version }}</a-tag>
      <div class="mt-2">
        <p v-html="localPlugin?.description"></p>
        <template v-for="(dataGroup, groupIndex) in pluginInputData" v-bind:key="'_dataGroup_' + groupIndex">
          <h4 v-if="dataGroup?.title">{{ dataGroup?.title }}</h4>
          <div class="row padd-10">
            <div class="col-4" v-if="dataGroup?.useBlueprint">
              <span style="min-width: 125px; margin-right: 15px">Select a blueprint: </span>
              <BlueprintSelect v-model="dataGroup.blueprintId" placeholder="Select a blueprint..." style="width: 100%" />
            </div>
            <div class="col-4">
              <div v-for="(field, fieldIndex) in dataGroup?.fields" v-bind:key="groupIndex + '_field_' + fieldIndex">
                <template v-if="field?.from === 'input'">
                  <span>{{ field?.label }}</span
                  ><br />
                  <PluginFieldWidget :field="field" v-model="field.value"></PluginFieldWidget>
                </template>
                <template v-else-if="dataGroup?.blueprintId">
                  <span>{{ field?.label }}</span
                  ><br />
                  <a-select
                    placeholder="Select field"
                    style="min-width: 100%"
                    v-model:value="field.fieldId"
                    :options="getBlueprintFields(dataGroup.blueprintId)"
                    :filter-option="filterOption"
                    show-search
                  >
                  </a-select>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="pluginEndpoints">
          <span>EndPoints:</span>
          <template v-for="point in pluginEndpoints" v-bind:key="point.path">
            <a-card>
              {{ point.path }} -> {{ point.description }}<br /><br />
              <span>Input Fields</span><br />
              <pre>{{ point.inputFields }}</pre>
              <span>Expected Fields</span><br />
              <pre>{{ point.expectedFields }}</pre>
            </a-card>
          </template>
          <div style="text-align: center">
            <a-button type="primary" :loading="savePluginLoading" style="margin-right: 10px" @click="save">{{ saveButtonLabel }}</a-button>
          </div>
        </template>
      </div>
    </a-card>
  </div>
</template>

<script>
import PluginFieldWidget from '@/apps/pluginsManagement/views/Builder/components/PluginFieldWidget.vue';
import { BlueprintSelect } from '@/apps/dataSystem/components/BlueprintSelect';
import { blueprintApi } from '@/apps/dataSystem/api';
import { PluginsGetters, PluginsActions } from '../../shared/pluginsManagement.store';

export default {
  name: 'PluginsList',
  components: { BlueprintSelect, PluginFieldWidget },
  data() {
    return {
      loading: false,
      pluginPath: this.$route.params.pluginPath ?? null,
      pluginInputData: [],
      blueprintList: [],
      isActive: true,

      savePluginLoading: false,
      plugin: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.init();
    this.loading = false;
  },
  computed: {
    selectedBlueprint() {
      return this.blueprintList.find(b => b._id === this.input.blueprintId);
    },
    pluginStructure: PluginsGetters.getStructure,
    pluginEndpoints() {
      return this.plugin?.endPoints ?? this.pluginStructure?.endPoints ?? [];
    },
    localPlugin() {
      return this.plugin ?? this.pluginStructure;
    },
    saveButtonLabel() {
      let update = 'INSTALL PLUGIN';
      if (this.plugin?._id) {
        update = 'SAVE PLUGIN';
        if (this.plugin.version !== this.pluginStructure.version) {
          update = 'UPDATE PLUGIN';
        }
      }
      return update;
    },
  },
  methods: {
    async init() {
      this.blueprintList = await blueprintApi.getAllWithFields();
      await PluginsActions.fetchPlugin(this.pluginPath);
      this.plugin = PluginsGetters.getPlugin();
      if (!this.plugin) {
        this.pluginStructureToInputData(this.pluginStructure);
      } else {
        this.pluginInputData = this.plugin?.data;
      }
    },
    getBlueprintFields(blueprintId) {
      const selectedBlueprint = this.blueprintList.find(b => b._id === blueprintId);
      let fields = [];
      fields = selectedBlueprint.fields.map(field => {
        return {
          value: field._id,
          label: field.label,
        };
      });
      fields.push({
        value: '_id',
        label: 'ID',
      });
      return fields;
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    pluginStructureToInputData(pluginStructure) {
      pluginStructure.data.forEach(dataGroup => {
        // const fields = [];
        // dataGroup.fields.forEach(dataGroupField => {
        //   fields.push({ label: dataGroupField.label, value: null, from: dataGroupField.from, inputType: dataGroupField.type });
        // });
        this.pluginInputData.push({ title: dataGroup.title, useBlueprint: dataGroup.useBlueprint, blueprintId: null });
      });
    },
    async save() {
      this.savePluginLoading = true;
      let endPoints = this.pluginStructure?.endPoints;
      if ((this.plugin?.endPoints ?? []).length) {
        endPoints = (this.pluginStructure?.endPoints ?? []).map((item, index) => {
          return {
            ...item,
            _id: this.plugin.endPoints[index] ?? undefined,
          };
        });
      }
      let dataParams = this.pluginInputData;
      if ((this.plugin?.data ?? []).length) {
        dataParams = (this.pluginInputData ?? []).map((item, index) => {
          return {
            ...item,
            _id: this.plugin?.data[index] ?? undefined,
          };
        });
      }
      const data = {
        ...(this.plugin ?? {}),
        title: this.pluginStructure?.title,
        path: this.pluginStructure?.path,
        description: this.pluginStructure?.description,
        version: this.pluginStructure?.version,
        output: this.pluginStructure?.output ?? null,
        endPoints,
        data: dataParams,
        isActive: this.isActive,
      };
      if (!this.plugin) {
        this.plugin = data;
      }
      await PluginsActions.savePlugin(this.pluginPath, data);
      this.plugin = PluginsGetters.getPlugin();
      this.savePluginLoading = false;
    },
  },
};
</script>
