<script>
import { h, defineAsyncComponent } from 'vue';
import { AppActions } from '@/App.store';
import { NewTemplatesMutations } from '@/apps/templateDataSystem/shared/newTemplateDataSystem.store';
import ProviderApi from '@/apps/dataProvider/api/provider.api';
import router from '@/router';
import { Modal, notification } from 'ant-design-vue';
import dataAuthRestApi from '@/apps/dataAuthRest/api/dataAuthRest.api';
import workflowApi from '@/apps/workflow/workflow.api';

const InputFile = defineAsyncComponent(() => import('./InputFile.vue'));

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  created() {
    this.localContent = this.content;
  },
  data() {
    return {
      templateRender: null,
      localContent: false,
    };
  },
  watch: {
    content(data) {
      this.localContent = data;
    },
  },
  render() {
    const r = {
      components: { InputFile },
      template: `${this.localContent}`,
      emits: ['buttonClicked'],
      data() {
        return {
          dataflowLoading: false,
        };
      },
      methods: {
        async extAuthRest(authRestId, resumeAfterRunBefore = false) {
          let localAuthRestId = authRestId;
          if (!authRestId) {
            localAuthRestId = 'auto';
          }
          AppActions.toggleSpinning(true);
          const authRestData = await dataAuthRestApi.runOne(localAuthRestId);
          if (authRestData.error) {
            Modal.error({
              title: authRestData.message,
              content: h('div', { innerHTML: `No data found!` }),
            });
          } else {
            if (authRestData.runBeforeAuthRestId && !resumeAfterRunBefore) {
              const beforeAuthRest = await dataAuthRestApi.runOne(authRestData.runBeforeAuthRestId);
              if (beforeAuthRest.error) {
                Modal.error({
                  title: beforeAuthRest.message,
                  content: h('div', { innerHTML: `No data found!` }),
                });
              }
              if (beforeAuthRest?.httpSubscription?.openInTab) {
                const query =
                  (beforeAuthRest.httpSubscription.query.filter(item => item.key).length ? '?' : '') +
                  beforeAuthRest.httpSubscription.query
                    .filter(item => item.key)
                    .map(obj => `${encodeURIComponent(obj.key)}=${encodeURIComponent(obj.value ?? '')}`)
                    .join('&');
                const url = beforeAuthRest.httpSubscription.url + query;
                const popup = window.open(url, 'popupWindow', 'width=600,height=400,scrollbars=yes');
                const popupCheckInterval = setInterval(() => {
                  if (popup.closed) {
                    clearInterval(popupCheckInterval);
                    this.extAuthRest(authRestId, true);
                  }
                }, 500);
              }
              AppActions.toggleSpinning(false);
              return;
            }
            if (authRestData.httpSubscription?.openInTab) {
              if (authRestData.httpSubscription?.url !== '') {
                const query =
                  (authRestData.httpSubscription.query.filter(item => item.key).length ? '?' : '') +
                  authRestData.httpSubscription.query
                    .filter(item => item.key)
                    .map(obj => `${encodeURIComponent(obj.key)}=${encodeURIComponent(obj.value ?? '')}`)
                    .join('&');
                window.open(authRestData.httpSubscription.url + query, '_blank');
              }
            } else {
              try {
                const returnedData = await dataAuthRestApi.runEndpoint(localAuthRestId, {});
                const errorString = Object.entries(returnedData?.response?.data ?? {})
                  .map(([key, value]) => `<b>${key}</b>: ${value}`)
                  .join('<br>');
                Modal.success({
                  title: 'Success',
                  closable: true,
                  content: h('div', { innerHTML: `${errorString === '' ? 'No data!' : errorString}` }),
                });
              } catch (error) {
                console.log(error);
                Modal.error({
                  title: 'Fail',
                  content: h('div', { innerHTML: error?.message }),
                });
              }
            }
          }
          AppActions.toggleSpinning(false);
        },
        generatePDF() {
          NewTemplatesMutations.SET_DOWNLOAD_PDF(true);
        },
        async runWorkflow(runWorkflowId) {
          // calendar_date is the value from the calendar field in template
          const params = new URLSearchParams(window.location.search);
          const calendarSelectedDate = params.get('calendar_date');
          const instance = {
            _calendarSelectedDate: calendarSelectedDate,
          };
          // run workflow manually
          await workflowApi.runWorkflowManually(runWorkflowId, instance);
        },
        goToTemplate(templateId) {
          const menuItemId = this.$route.params.menuItemId ?? null;
          const instanceId = this.$route.params.instanceId ?? null;

          const params = { viewType: 'open', templateId };
          if (this.$route.params.templateId) {
            if (this.$route.params.templateId !== templateId) {
              params.oldTemplateId = this.$route.params.templateId;
            }
          }
          if (menuItemId) {
            params.menuItemId = menuItemId;
          }
          if (instanceId) {
            params.instanceId = instanceId;
          }
          router
            .push({
              name: 'templateDataSystemListOpenTemplate',
              params,
            })
            .catch(() => {});
        },
        async runManualDataflow(providerId) {
          this.dataflowLoading = true;

          const openInstanceId = this.$route.params.instanceId ?? null;
          const templateId = this.$route.params.templateId ?? null;

          try {
            const response = await ProviderApi.runManualDataflow(providerId, {
              openInstanceId,
              templateId,
            });

            const { data } = response;
            console.log(response.headers);
            console.log('RESPONSE DATA:');
            console.log(data);

            const contentDisposition = response.headers['content-disposition'];
            const contentType = response.headers['content-type'];
            if (contentDisposition && contentDisposition.includes('attachment')) {
              // Extract filename from Content-Disposition header
              const filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '');
              const blob = new Blob([response.data], {
                type: contentType || 'application/octet-stream',
              });

              // Log blob details for debugging
              console.log('Blob size:', blob.size);
              console.log('Blob type:', blob.type);

              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              const text = await response.data.text();
              const jsonData = JSON.parse(text);

              const successOrFailure = jsonData.success ? 'success' : 'warning';
              notification[successOrFailure]({
                message: jsonData.success ? 'Finished' : 'Error',
                description: jsonData.message,
              });
            }
          } catch (error) {
            notification.error({
              message: 'Error',
              description: error.message,
            });
          } finally {
            this.dataflowLoading = false;
          }
        },
        trigger(data) {
          if (typeof data === 'object') {
            this.$emit('buttonClicked', data);
          } else {
            const obj = JSON.parse(this.decodeHtmlCharCodes(data));
            this.$emit('buttonClicked', obj);
          }
        },

        decodeHtmlCharCodes(str) {
          return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
          });
        },
      },
    };
    return h(r);
  },
};
</script>
