<template>
  <div class="mt-3 padd-10">
    <a-tabs type="card" v-model:activeKey="selectedTab">
      <a-tab-pane :key="1" tab="Menus">
        <div v-if="selectedTab === 1">
          <h3>Menus</h3>

          <a-button type="primary" class="mb-3" @click="addMenu">Add new menu</a-button>

          <div v-for="menu in localMenus" :key="menu._id + '-' + key" :class="'saas_card mb-10 hoverable' + (!menu.active ? ' inactive' : '')">
            <div class="row-contents">
              <div class="group-text-container" @click="openMenu(menu._id)">
                <a data-cy="linkSubtenantSelect">
                  <div class="group-text">
                    <span class="title">{{ menu.name }}</span>
                  </div>
                </a>
              </div>
              <div class="metadata">
                <div class="controls">
                  <a-tooltip placement="top">
                    <template #title>
                      <span>Edit</span>
                    </template>
                    <a-button @click="editMenu(menu._id)" class="mr-10"><EditOutlined /> </a-button>
                  </a-tooltip>

                  <a-popconfirm
                    placement="topLeft"
                    :title="'Are you sure you want to delete `' + menu.name + '`?'"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="removeOne(menu._id)"
                  >
                    <a-button :loading="deleteOneLoading === menu._id"><DeleteOutlined /> </a-button>
                  </a-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-tab-pane>

      <a-tab-pane :key="2" tab="Layout">
        <MenuLayout v-if="selectedTab === 2" />
      </a-tab-pane>
    </a-tabs>

    <component
      :is="'a-modal'"
      :footer="false"
      :width="'800px'"
      v-model:open="modal.show"
      :destroyOnClose="true"
      :title="modal.edit ? 'Edit menu' : 'Add menu'"
      :maskClosable="false"
      @v-model:close="closeModal"
    >
      <div class="mb-3">
        <a-input v-model:value="formData.name" placeholder="Name" />
      </div>
      <div class="mb-3">
        <a-textarea v-model:value="formData.description" placeholder="Description" />
      </div>

      <div class="mb-3">
        <a-checkbox style="margin-bottom: 10px" v-model:checked="formData.allSubtenants" @change="updateSubtenantsIdList"> All subtenants</a-checkbox>
        <a-select
          mode="tags"
          placeholder="Subtenants..."
          :value="formData.subtenantId"
          v-model:value="formData.subtenantId"
          style="width: 100%"
          v-if="!formData.allSubtenants"
        >
          <template v-for="subtenant of subtenants" :key="subtenant._id">
            <a-select-option :value="subtenant._id">{{ subtenant.name }}</a-select-option>
          </template>
        </a-select>
      </div>

      <div class="mb-3" v-if="modal.edit">
        <a-button type="primary" @click="modalStartPagesVisible = true">Configure start page(s)</a-button>
      </div>

      <div class="mb-3">
        <a-switch checked-children="Enabled" :checked="formData.active" v-model:checked="formData.active" un-checked-children="Disabled" />
      </div>

      <div>
        <a-row>
          <a-col :span="12">
            <a-button :loading="(menuLoading && menuLoading === modal.menuId) || addOneLoading" type="primary" @click="processForm">
              <template v-if="modal.edit"><EditOutlined /> Edit</template>
              <template v-else><PlusOutlined /> Create</template>
            </a-button>
          </a-col>
          <a-col :span="12" style="text-align: right">
            <a-button key="back" @click="closeModal">Cancel</a-button>
          </a-col>
        </a-row>
      </div>
    </component>

    <a-modal v-model:open="modalStartPagesVisible" title="Menu start page(s)" :zIndex="1001">
      <a-button type="primary" size="small" @click="addStartingPage" class="mb-3">Add a Starting page</a-button>

      <div>
        <div style="display: flex; align-items: center" class="mb-3" v-for="(startMenu, i) in formData.startMenus" :key="i">
          <div>{{ i + 1 }}.</div>
          <div class="col-md-10" v-if="menuWithTemplate">
            <a-tree-select
              v-model:value="startMenu.startMenuId"
              style="width: 100%; margin-bottom: 2px"
              :tree-data="menuWithTemplate"
              placeholder="Select start page"
              :replaceFields="{ key: '_id', value: '_id', children: 'children' }"
              :tree-default-expanded-keys="getExpandedKeys(startMenu.startMenuId, menuWithTemplate)"
            />
            <br />

            <!--            <a-radio-group v-model:value="startMenu.rolesMode" style="margin-bottom: 2px">
              <a-radio-button value="all">
                ALL
              </a-radio-button>
              <a-radio-button value="none">
                NONE
              </a-radio-button>
              <a-radio-button value="custom">
                CUSTOM
              </a-radio-button>
            </a-radio-group>
            <br>-->

            <a-select
              mode="tags"
              placeholder="For these user roles..."
              :value="startMenu.userRoles"
              v-model:value="startMenu.userRoles"
              style="width: 100%; padding-top: 10px"
            >
              <template v-for="appRole of appRolesOptions" :key="appRole._id">
                <a-select-option :value="appRole._id">{{ appRole.name }}</a-select-option>
              </template>
            </a-select>
          </div>
          <div class="col-md-2">
            <a-tooltip title="Remove start page" v-if="i > 0">
              <a-button style="border: 0; box-shadow: none" @click="removeStartPage(i)">
                <MinusOutlined />
              </a-button>
            </a-tooltip>
          </div>
        </div>
      </div>

      <template #footer>
        <a-button key="back" @click="modalStartPagesVisible = false"> Close </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { MenuGetters, MenuActions } from '@menuBuilder/shared/menuBuilder.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';

import MenuLayout from '@/apps/menuBuilder/views/List/components/MenuLayout.vue';
import { findRecursive, objectId } from '@/core/utils/array-manipulation';
import { roleApplicationApi } from '@roleManagement/api';
import { DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import _ from 'lodash';

const defaultFormMenu = {
  name: '',
  description: '',
  subtenantId: [],
  manuBuilder: [],
  active: true,
};

export default {
  name: 'MenuList',
  computed: {
    menuLoading: MenuGetters.getMenuLoading,
    menus: MenuGetters.getMenus,
    subtenants: TenantsGetters.getAllSubtenants,
    menuWithTemplate() {
      const menuBuilderCopy = _.cloneDeep(this.formData.menuBuilder);
      if (menuBuilderCopy) {
        menuBuilderCopy.forEach(item => {
          if (item?.templateId === null) {
            item.disabled = true;
          }
        });
        return menuBuilderCopy;
      }
      return null;
    },
    appRolesOptions() {
      return this.appRoles.map(role => {
        return {
          name: role.name,
          _id: role._id,
        };
      });
    },
  },
  components: {
    MinusOutlined,
    MenuLayout,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
  },
  data() {
    return {
      addOneLoading: false,
      deleteOneLoading: null,
      selectedTab: 1,

      key: objectId(),

      localMenus: [],

      formData: { ...defaultFormMenu },

      modal: {
        show: false,
        edit: false,
        menuId: null,
      },
      modalStartPagesVisible: false,
      appRoles: [],
    };
  },
  async created() {
    await MenuActions.init();
    this.appRoles = await roleApplicationApi.getAllForApplication();
    this.localMenus = this.menus;
  },
  watch: {
    menus(value) {
      this.localMenus = value;
    },
  },
  methods: {
    addStartingPage() {
      this.formData.startMenus.push({
        startMenuId: null,
        rolesMode: 'all', // all, none, custom
        userRoles: [],
      });
    },
    removeStartPage(index) {
      this.formData.startMenus.splice(index, 1);
    },

    async setStartPage(menu, startMenuId) {
      const selectedMenu = await findRecursive(menu.menuBuilder, startMenuId);
      if (selectedMenu && !selectedMenu.templateId) {
        this.key = objectId();
        message.error(`${selectedMenu.title} does not have a template associated!`);
      } else {
        const formData = {
          ...menu,
          startMenuId: startMenuId ?? null,
        };
        await MenuActions.startPage(menu._id, formData);
        this.localMenus = this.localMenus.map(item => {
          if (item._id === menu._id) {
            return { ...item, startMenuId };
          }
          return { ...item, startMenuId: null };
        });
        this.key = objectId();
        if (selectedMenu) {
          message.success(`${selectedMenu.title} has been set as start page!`);
        } else {
          message.success(`${menu.name} has been updated!`);
        }
      }
    },

    async processForm() {
      const formData = { ...this.formData };
      if (this.modal.menuId) {
        await MenuActions.editOne(this.modal.menuId, formData);
      } else {
        this.addOneLoading = true;
        await MenuActions.createOne(formData);
        this.addOneLoading = false;
      }
      this.modal.show = false;
    },
    async removeOne(menuId) {
      this.deleteOneLoading = menuId;
      await MenuActions.deleteOne(menuId);
      this.deleteOneLoading = null;
    },
    closeModal() {
      this.modal.show = false;
      this.modal.menuId = null;

      this.formData = { ...defaultFormMenu };
    },
    addMenu() {
      this.modal.show = true;
      this.modal.edit = false;

      this.formData = {
        allSubtenants: true,
        subtenantId: this.subtenants?.map(subtenant => subtenant._id) ?? [],
      };
    },
    editMenu(menuId) {
      this.modal.show = true;
      this.modal.edit = true;
      this.modal.menuId = menuId;

      const menu = this.menus.find(item => item._id === menuId);

      this.formData = { ...menu };
      if (typeof this.formData?.allSubtenants === 'undefined') {
        this.formData.allSubtenants = this.formData?.subtenantId?.length === this.subtenants?.length;
      }
    },
    openMenu(menuId) {
      this.$router.push({ name: 'MenuBuilder', params: { menuId } });
    },
    getExpandedKeys(targetKey, nodes, path = []) {
      return nodes.reduce((acc, node) => {
        if (acc.length > 0) return acc; // If the path is already found, skip further processing

        const newPath = [...path, node._id]; // Append the current node's _id to the path

        if (node._id === targetKey) {
          return newPath; // Found the target key, return the path
        }

        if (node.children && node.children.length > 0) {
          const result = this.getExpandedKeys(targetKey, node.children, newPath); // Recursively search children
          if (result.length > 0) {
            return result; // If the result is found in the children, return the path
          }
        }

        return acc; // Return the accumulated path (empty if not found)
      }, []);
    },
    updateSubtenantsIdList(value) {
      console.log();
      if (!value?.target?.checked && !this.formData?.subtenantId.length) {
        this.formData.subtenantId = this.subtenants?.map(item => item._id);
      }
    },
  },
};
</script>

<style scoped>
.inactive {
  background-color: rgba(155, 94, 94, 0.1);
  border-color: #e09393;
}
</style>
