<template>
  <a-drawer title="Toggle Two Factor Authentication" width="500px" :open="visible" @close="emitClose">
    <div style="margin-top: 20px">
      <a-checkbox v-model:checked="tfaValue"> Two Factor Authentication is enabled. </a-checkbox>
    </div>
    <div style="margin-top: 20px">
      <p>Choose the action mode of the Two Factor Authentication.</p>

      <a-radio-group v-model:value="method">
        <a-radio-button value="email">Email</a-radio-button>
        <a-radio-button value="google">Google Authenticator</a-radio-button>
      </a-radio-group>
    </div>
    <div style="margin-top: 30px">
      <a-button @click="onClickSend" type="primary"> Save </a-button>
    </div>

    <div style="margin-top: 20px">
      <div v-if="qrcodeURL">
        <p>This QR code is visible only for the moment. Please consider resaving the Two Factor Authentication for generating a new one.</p>
        <img :src="qrcodeURL" alt="QR Code URL for Auth." />
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { userApi } from '@userManagement/api';
import { UserGetters } from '@userManagement/user.store';
import { message } from 'ant-design-vue';

export default {
  props: ['visible'],
  emits: ['close'],
  data() {
    return {
      tfaValue: false,
      method: 'email',
      userId: null,
      qrcodeURL: null,
    };
  },
  computed: {
    user: UserGetters.getUser,
  },
  watch: {
    user: {
      handler(newUser) {
        if (newUser && 'is2FAEnabled' in newUser && 'is2FAOverEmail' in newUser) {
          this.userId = newUser._id;
          this.tfaValue = newUser.is2FAEnabled;
          this.method = newUser.is2FAOverEmail ? 'email' : 'google';
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.user && 'is2FAEnabled' in this.user && 'is2FAOverEmail' in this.user) {
      this.userId = this.user._id;
      this.tfaValue = this.user.is2FAEnabled;
      this.method = this.user.is2FAOverEmail ? 'email' : 'google';
    }
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    async onClickSend() {
      const tfa = await userApi.toggleTwoFactorAuth(this.userId, this.tfaValue, this.method === 'email');

      if (tfa.status === 'success') {
        if (this.method === 'email') {
          this.qrcodeURL = null;
        } else {
          this.qrcodeURL = tfa.data;
        }

        message.success(tfa.message);
      } else {
        message.error(tfa.message);
      }
    },
  },
};
</script>

<style></style>
