import axios from 'axios';

export const logoUpload = async fileInput => {
  try {
    const form = new FormData();

    // Check if fileInput is an HTML input element or a File object
    if (fileInput instanceof File) {
      form.append('file', fileInput);
    } else if (fileInput.files && fileInput.files[0]) {
      form.append('file', fileInput.files[0]);
    } else {
      throw new Error('Invalid fileInput: Expected a File or input element.');
    }

    const response = await axios.post(`/public/data-system/uploadSubtenantLogo`, form);

    return {
      error: false,
      message: response.data,
    };
  } catch (error) {
    console.error('API Error:', error);
    return {
      error: true,
      message: error.response?.data?.error || 'An error occurred during file import.',
    };
  }
};
