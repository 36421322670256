<template>
  <div class="TokenLogin_Container">
    <loading-spinner v-if="isLoading" />
    <a-card v-else>
      <h1>Two Factor Authentication</h1>

      <label class="TokenLogin_Label" for="username">Use the code sended via Email / Google Authenticator to log in.</label>
      <a-input v-model:value="input.token" id="token" type="text" data-cy="inputToken" />

      <div v-if="tokenError">
        <p style="font-weight: 500; color: #a8071a; margin-top: 5px; margin-bottom: 0">
          {{ tokenError }}
        </p>
      </div>

      <div v-if="authError">
        <p style="font-weight: 500; color: #a8071a; margin-top: 5px; margin-bottom: 0">There was an error logging in. Please try again.</p>
      </div>

      <a-button class="TokenLogin_LoginButton" type="primary" @click="onClickAuth" data-cy="buttonLogin"> <LoginOutlined /> Login </a-button>
    </a-card>
  </div>
</template>

<script>
import { UserActions } from '@userManagement/user.store';
import { LoginOutlined } from '@ant-design/icons-vue';

export default {
  name: 'TFAComponent',
  components: {
    LoginOutlined,
  },
  params: ['userId'],
  data() {
    return {
      isLoading: false,
      tokenError: false,
      authError: null,
      input: {
        token: null,
      },
    };
  },
  async created() {
    this.isLoading = true;

    try {
      await UserActions.silentLogin(true);
    } catch (error) {
      console.log('User was not log automated logged in.');
    }

    this.isLoading = false;
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
  },
  methods: {
    async onClickAuth() {
      if (!this.input.token || this.input.token.trim() === '') {
        this.tokenError = 'Token is required.';
        return;
      }

      if (this.input.token.length !== 6) {
        this.tokenError = 'Token is invalid. It has exactly 6 characters long.';
        return;
      }

      this.isLoading = true;

      try {
        await UserActions.tfa(this.userId, this.input.token);
      } catch {
        this.authError = true;
      }
      this.isLoading = false;
    },
  },
  watch: {
    input: {
      deep: true,
      handler() {
        this.tokenError = null;
        this.authError = false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.TokenLogin {
  &_Container {
    margin-top: -51px;
    padding-top: 51px;
    background-color: #f5f5f5;
    height: 100vh;
  }
  &_Label {
    display: block;
    margin-top: 20px;
  }
  &_LoginButton {
    margin-top: 25px;
    width: 150px;
  }
}
</style>
