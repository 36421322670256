<template>
  <a-modal :open="openModal" wrap-class-name="full-modal" title="Conversatie" @ok="closeModal" @cancel="closeModal">
    <div class="messages" ref="messagesContainer">
      <div
        v-for="message in conversation.messages"
        :key="message._id"
        :class="`message message--${String(message.sender).includes('aPlay') ? 'right' : 'left'}`"
      >
        <div class="message__header">{{ message.sender }}</div>

        <div class="message__body">
          <div v-if="'mediaId' in message.content">
            <a-button @click="DownloadMedia(message.mediaId)"> Descarca fisier </a-button>
          </div>
          <div v-if="'text' in message.content">
            {{ message.content.text }}
          </div>
          <div v-if="'caption' in message.content">
            {{ message.content.caption }}
          </div>
        </div>
      </div>
    </div>

    <a-row class="messages__footer">
      <a-col span="24" class="mb-3">
        <a-textarea placeholder="Mesaj de trimis" v-model:value="messageText"></a-textarea>
        <span style="text-align: right; display: block">Numar caractere: {{ characterCount }}</span>
      </a-col>

      <a-col span="24">
        <a-button type="primary" @click="sendWhatsApp" :loading="loading" class="mr-2">Trimite mesaj</a-button>
        <a-button type="primary" @click="refresh" :loading="loading">Refresh</a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { instanceApi } from '@dataSystem/api';
import { notification } from 'ant-design-vue';
import { WhatsAppManagementApi } from '@whatsappManagement/api';
import { nextTick } from 'vue';

export default {
  data() {
    return {
      loading: false,
      messageText: '',
      conversation: {},
    };
  },
  props: ['conversationId', 'openModal'],
  emits: ['closeModal'],
  watch: {
    async conversationId(value) {
      if (value !== 0) {
        this.conversation = await WhatsAppManagementApi.findOne(value);
        await nextTick();
        this.scrollToBottom();
      }
    },
  },
  computed: {
    characterCount() {
      return this.messageText.length;
    },
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    async sendWhatsApp() {
      this.loading = true;

      if (this.messageText.length > 4) {
        try {
          await instanceApi.sendWhatsAppMessage([this.conversation.to], this.messageText, this.conversationId, this.conversation.instanceId);

          this.successMessage();
          this.messageText = '';
        } catch (error) {
          this.showErrorMessage(error.response.data.error);
        }
      } else {
        this.warningShortMessage();
      }

      await this.refresh();
      this.loading = false;
    },
    async DownloadMedia(mediaId) {
      await WhatsAppManagementApi.getMediaSource(mediaId);
    },
    async refresh() {
      this.conversation = await WhatsAppManagementApi.findOne(this.conversationId);
      await nextTick();
      this.scrollToBottom();
    },
    successMessage() {
      notification.success({
        message: 'WhatsApp trimis',
        description: `La urmatorul numar de telefon ${this.conversation.to} am trimis mesajul: ${this.messageText}`,
      });
    },
    warningShortMessage() {
      notification.warning({
        message: 'Eroare',
        description: `Introduceti cel putin 5 caractere in mesajul Dvs.`,
      });
    },
    showErrorMessage(error) {
      notification.warning({
        message: 'Eroare',
        description: error,
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<style scoped>
.messages {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.message {
  width: min-content;
  background: rgb(225, 225, 255);
  border-radius: 6px;
  padding: 12px 6px;
  margin: 5px;
}

.message--left {
  margin-right: auto;
}

.message--right {
  margin-left: auto;
}

.message__header {
  font-weight: bold;
}

.message__body {
  width: 300px;
}

.messages__footer {
  background: rgb(225, 225, 255);
  border-radius: 6px;
  padding: 20px;
  margin: 5px;
}
</style>
