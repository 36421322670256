import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

const optionsToQueryParams = (page, limit, sorters, filters) => {
  let queryParams = '';

  if (page) queryParams += `page=${page}`;
  if (limit) queryParams += `${queryParams.length === 0 ? '' : '&'}limit=${limit}`;

  Object.keys(sorters).forEach(key => {
    if (sorters[key] === '' || sorters[key] === null || sorters[key] === undefined) return;

    queryParams += `${queryParams.length === 0 ? '' : '&'}sort_${key}=${sorters[key]}`;
  });
  Object.keys(filters).forEach(key => {
    if (filters[key] === '' || filters[key] === null || filters[key] === undefined) return;

    if (key === 'initiatedAt') {
      if (filters[key].from) {
        queryParams += `${queryParams.length === 0 ? '' : '&'}filter_${key}From=${filters[key].from}`;
      }

      if (filters[key].to) {
        queryParams += `${queryParams.length === 0 ? '' : '&'}filter_${key}To=${filters[key].to}`;
      }
    } else {
      queryParams += `${queryParams.length === 0 ? '' : '&'}filter_${key}=${filters[key]}`;
    }
  });

  return queryParams;
};

const findOne = async conversationId => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/whatsapp/conversation/${conversationId}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const findAllForSubtenant = async (page, limit, sorters, filters) => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/whatsapp?${optionsToQueryParams(page, limit, sorters, filters)}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const findAllForInstance = async (instanceId, page, limit, sorters, filters) => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/whatsapp/${instanceId}?${optionsToQueryParams(page, limit, sorters, filters)}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const findAllForUserAndSubtenant = async (page, limit, sorters, filters) => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/whatsapp/user?${optionsToQueryParams(page, limit, sorters, filters)}`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const getMediaSource = async (mediaId, type) => {
  try {
    // Make the request for media data
    const response = await axios.get(
      `${getSubtenantUrl()}/whatsapp/media?id=${mediaId}`,
      { responseType: 'arraybuffer' } // Important: Set response type for binary data
    );

    // Determine MIME type based on the 'type' parameter
    let mimeType = '';
    let extension = '';

    switch (type) {
      case 'DOCUMENT': // ONLY FOR PDF
        mimeType = 'application/pdf'; // Or another MIME type for documents
        extension = 'pdf'; // Adjust the file extension for documents
        break;
      case 'IMAGE':
        mimeType = 'image/jpeg'; // Example: assuming video is MP4
        extension = 'jpg';
        break;
      case 'VIDEO':
        mimeType = 'video/mp4'; // Example: assuming video is MP4
        extension = 'mp4';
        break;
      case 'AUDIO':
        mimeType = 'audio/mp3'; // Example: assuming audio is MP3
        extension = 'mp3';
        break;
      case 'CONTACT':
        mimeType = 'text/vcard'; // Assuming contact is vCard format
        extension = 'vcf';
        break;
      case 'LOCATION':
        mimeType = 'application/json'; // Assuming location is in JSON format
        extension = 'json';
        break;
      default:
        throw new Error('Unsupported media type');
    }

    // Convert the binary response into a Blob with the correct MIME type
    const blob = new Blob([response.data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${mediaId}.${extension}`); // Set filename with appropriate extension
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading media file:', error);
  }
};

export default {
  findOne,
  findAllForInstance,
  findAllForSubtenant,
  findAllForUserAndSubtenant,
  getMediaSource,
};
